<template>
  <div class="productInformationDetalis">
    <div class="banner" :style="{background:'url(' + dataJson.banner.bgImg + ')',backgroundSize: '100% 100%', backgroundPosition: '50% 50%'}">
      <div class="content">
        <div class="box">
          <div class="title" data-aos="fade-up">{{dataJson.banner.title}}</div>
          <div class="desc" data-aos="fade-up">{{dataJson.banner.desc}}</div>
          <div class="desc_details" data-aos="fade-up">{{dataJson.banner.desc_details}}</div>
        </div>
        <div class="cBox">
          <img class="img" :src="dataJson.banner.cImg" alt="" srcset="">
          <div class="text">{{dataJson.banner.cText}}</div>
        </div>
      </div>
    </div>
    <div class="blockOne">
      <div class="content">
        <div class="title">{{ dataJson.content.htitle }}</div>
        <div class="msg" v-if="dataJson.content.hmsg">{{ dataJson.content.hmsg }}</div>
        <div class="itemBox">
          <div class="item" v-for="(item,index) in dataJson.content.item" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="li" v-html="item.list"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="blockTwo">
      <div class="content">
        <div class="title">{{ dataJson.content2.htitle }}</div>
        <div class="imgBox">
          <img class="img" :src="dataJson.content2.img1" alt="" srcset="">
          <img class="img" :src="dataJson.content2.img2" alt="" srcset="" v-if="dataJson.content2.img2">
        </div>
        <div class="itemBox">
          <div class="item" v-for="(item,index) in dataJson.content2.item" :key="index">
            <div class="name">{{item.name}}</div>
            <div class="li" v-html="item.list"></div>
          </div>
        </div>
      </div> 
    </div>
    <div class="blockThree">
      <div class="content">
        <div class="title">{{ dataJson.content3.htitle }}</div>
        <div class="itemBoxAll">
          <div class="itemBox">
            <div class="item" v-for="(item,index) in dataJson.content3.item" :key="index">
              <div class="name">{{item.name}}</div>
              <div class="li" v-html="item.list"></div>
            </div>
          </div>
          <div class="itemBox">
            <div class="item" v-for="(item,index) in dataJson.content3.item2" :key="index">
              <div class="name">{{item.name}}</div>
              <div class="li" v-html="item.list"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {baseHostUrl,getproductsdataPost} from '@/utils/index.js'
export default {
  name: 'productInformationDetalis',
  components: {

  },
  data () {
    return {
      pcOrmb: true,
      page: 1,
      productsdata: [],
      baseHostUrl: '',
      count: 0,
      noData: false,
      dataJson: {},
      dataJsonC100: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance SmartNIC — metaConnect-100',
          desc: 'Product Introduction',
          desc_details: 'Yunsilicon metaConnect-100 smart network interface cards（SmartNIC）deliver cutting-edge RoCEv2 solutions for HPC/AI/ML and storage clusters. metaConnect-100 SmartNICs support a single-port of 100GbE, with networking acceleration capabilities on top of a powerful programmable FPGA platform.',
          cImg: require('../assets/20240205/metaConnect-100.png'),
          cText: 'metaConnect-100'
        },
        content: {
          htitle: 'Product Highlights',
          hmsg: 'Yunsilicon metaConnect-100 SmartNIC supports 100Gbps Ethernet speeds with hardware offload and acceleration to optimize workload performance. Based on a powerful programmable FPGA platform. metaConnect-100 offers a 100GbE RoCEv2 solution for HPC/AI/ML and storage cluster network.',
          item: [
            {
              name: 'RoCEv2',
              list: ` •  RDMA Send/Recv, RDMA Write and RDMA Read <br>
                      •  GPUDirect RDMA <br>
                      •  100Gbps throughput with Single QP <br>
                      •  Inline data supported <br>
                    `
            },
            {
              name: 'Networking offload and Acceleration',
              list: ` •  H-QoS<br>
                      •  VirtIO-net, SR-IOV and TSO<br>
                    `
            },
            {
              name: 'Programmable Congestion  Control',
              list: ` •  PFC, DCQCN Congestion Control<br>
                      •  Support Event-based and RTT-based PCC<br>
                      •  Support rate-limit<br>

                    `
            },
            {
              name: 'Storage Offload and Acceleration',
              list: ` •  VirtIO-blk and SR-IOV<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  Multi-Path<br>

                    `
            }
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaConnect系列.png'),
          img2: require('../assets/20240205/metaConnect系列-2.png'),
          item: [
            {
              name: 'High Performance Storage',
              list: ` •  100Gbps RoCEv2 Network<br>
                      •  Thousands of RDMA QPs<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  SPDK-based storage service<br>
                      •  Parallel File Storage<br>
                      •  Support Distributed Object Storage<br>
                    `
            },
            {
              name: 'HPC and AI/ML',
              list: ` •  100Gbps RoCEv2 Network improves the efficiency of computing <br>
                      •  HyperDirect and GPUDirect RDMA<br>
                      •  PFC and DCQCN, Go-Back-N repeat protocol<br>
                      •  Programmable Congestion Control interface(FlexCC) allows full programmability<br>
                      •  Adaptive Path and QP trace<br>
                      •  Standard Verbs interfaces，support NCCL and openMPI<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Adapter Hardware',
              list: ` •  Size: FHHL<br>
                      •  Host interface: PCIe Gen4.0 x16<br>
                      •  Network interface: 1x100GbE QSFP28<br>
                      •  TDP: 75W<br>

                    `
            },
            {
              name: 'RoCEv2',
              list: ` •  RoCEv2 networking<br>
                      •  HyperDirect and GPUDirect RDMA<br>
                      •  Programmable Congestion Control interface<br>
                      •  PFC and DCQCN<br>
                      •  Go-Back-N Repeat protocol<br>
                      •  QP Trace<br>
                      •  Adaptive Path<br>
                      •  NCCL and OpenMPI<br>
                    `
            },
            {
              name: 'Stateless offloads',
              list: ` •  TSO, checksum offload<br>
                      •  VLAN tag offload<br>
                      •  RSS offload<br>
                      •  FDR, Receive flow steering<br>
                    `
            },
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  SR-IOV <br>
                    `
            },
            {
              name: 'Networking',
              list: ` •  End-to-end QoS and congestion control<br>
                      •  Flow-based statistics<br>

                    `
            },
            {
              name: 'Storage Accelerations',
              list: ` •  RDMA Storage networking<br>
                      •  NVMe-oF offload<br>
                    `
            },
            {
              name: 'Manageability and Remote Boot',
              list: ` •  SMBus/I2C, NC-SI, UART<br>
                      •  PXE booting and UEFI<br>
                      •  FRU, CPLD<br>
                      •  Firmware upgrade and Management Tools<br>
                    `
            },
          ]
        }
      },
      dataJsonC200: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance SmartNIC — metaConnect-200',
          desc: 'Product Introduction',
          desc_details: 'Yunsilicon metaConnect-200 smart network interface cards（SmartNIC）deliver cutting-edge RoCEv2 solutions for HPC/AI/ML and storage clusters. metaConnect-200 SmartNICs support two ports of 100GbE, with networking acceleration capabilities on top of a powerful programmable FPGA platform.',
          cImg: require('../assets/20240205/metaConnect-200.png'),
          cText: 'metaConnect-200'
        },
        content: {
          htitle: 'Product Highlights',
          hmsg: 'Yunsilicon metaConnect-200 SmartNIC offers an optimized solution for HPC/AI/ML and computational storage network.',
          item: [
            {
              name: 'RoCEv2',
              list: ` •	 RDMA Send/Recv, RDMA Write and RDMA Read <br>
                      •	 GPUDirect RDMA <br>
                      •  100Gbps throughput with Single QP <br>
                      •  Inline data supported <br>
                    `
            },
            {
              name: 'Networking offload and Acceleration',
              list: ` •  H-QoS<br>
                      •  SR-IOV and TSO<br>
                    `
            },
            {
              name: 'Programmable Congestion Control',
              list: ` •  PFC, DCQCN Congestion Control<br>
                      •  Support Event-based and RTT-based PCC<br>
                      •  Support rate-limit<br>

                    `
            },
            {
              name: 'Storage Offload and Acceleration',
              list: ` •  SR-IOV<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  Multi-Path<br>

                    `
            }
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaConnect系列.png'),
          img2: require('../assets/20240205/metaConnect系列-2.png'),
          item: [
            {
              name: 'High performance Storage',
              list: ` •  100Gbps RoCEv2 Network<br>
                      •  Thousands of RDMA QPs<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  SPDK-based storage service<br>
                      •  Parallel File Storage<br>
                      •  Support Distributed Object Storage<br>
                    `
            },
            {
              name: 'HPC and AI/ML',
              list: ` •  100Gbps RoCEv2 Network improves the efficiency of computing <br>
                      •  HyperDirect， GPUDirect RDMA<br>
                      •  PFC and DCQCN, Go-Back-N repeat protocol<br>
                      •  Programmable Congestion Control interface(FlexCC) allows full programmability<br>
                      •  Adaptive Path and QP Trace<br>
                      •  Standard Verbs interfaces，support NCCL and openMPI<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Adapter Hardware',
              list: ` •  Size: FHHL<br>
                      •  Host interface: PCIe Gen4.0 x16<br>
                      •  Network interface: 2x100GbE QSFP28<br>
                      •  TDP: 75W<br>

                    `
            },
            {
              name: 'RoCEv2',
              list: ` •  RoCEv2 networking<br>
                      •  HyperDirect and GPUDirect RDMA<br>
                      •  Programmable Congestion Control interface<br>
                      •  PFC and DCQCN<br>
                      •  Go-Back-N Repeat protocol<br>
                      •  QP Trace<br>
                      •  Adaptive Path<br>
                      •  NCCL and OpenMPI<br>
                    `
            },
            {
              name: 'Stateless offloads',
              list: ` •  TSO, checksum offload<br>
                      •  VLAN tag offload<br>
                      •  RSS offload<br>
                      •  FDR, Receive flow steering<br>
                    `
            },
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  SR-IOV <br>
                    `
            },
            {
              name: 'Networking',
              list: ` •  End-to-end QoS and congestion control<br>
                      •  Flow-based statistics<br>

                    `
            },
            {
              name: 'Storage Accelerations',
              list: ` •  RDMA Storage networking<br>
                      •  NVMe-oF offload<br>
                    `
            },
            {
              name: 'Manageability and Remote Boot',
              list: ` •  SMBus/I2C, NC-SI, UART<br>
                      •  PXE booting and UEFI<br>
                      •  FRU, CPLD<br>
                      •  Firmware upgrade and Management Tools<br>
                    `
            },
          ]
        }
      },
      dataJsonC50: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance SmartNIC — metaConnect-50',
          desc: 'Product Introduction',
          desc_details: 'Yunsilicon metaConnect-50 smart network interface cards（SmartNIC）deliver cutting-edge RoCEv2 solutions for HPC/AI/ML and storage clusters. metaConnect-50 SmartNICs support two ports of 25GbE, with networking acceleration capabilities on top of a powerful programmable FPGA platform.',
          cImg: require('../assets/20240205/metaConnect-50.png'),
          cText: 'metaConnect-50'
        },
        content: {
          htitle: 'Product Highlights',
          hmsg: 'Yunsilicon metaConnect-50 SmartNIC offers an optimized solution for HPC/AI/ML and computational storage network.',
          item: [
            {
              name: 'RoCEv2',
              list: ` •	 RDMA Send/Recv, RDMA Write and RDMA Read <br>
                      •	 GPUDirect RDMA <br>
                      •  2x25Gbps throughput with Single QP <br>
                      •  Inline data supported <br>
                    `
            },
            {
              name: 'HyperDirect and Programmable Congestion  Control',
              list: ` •  Support GPUDirect RDMA technology<br>
                      •  Provide programmable congestion control interface for users to develop CC algorithms based on various scenarios<br>
                      •  Support diversified congestion control factors (such as DCQCN alpha/timeout, Flow CNP, etc.) and multi-granular flow control capabilities (such as rate-based, window, etc.)<br>
                    `
            },
            {
              name: 'Networking and Storage  Acceleration',
              list: ` •  H-QoS<br>
                      •  Tunneling/tso/checksum offload<br>
                      •  SR-IOV<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  Multi-Path<br>
                    `
            }
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaConnect系列.png'),
          img2: require('../assets/20240205/metaConnect系列-2.png'),
          item: [
            {
              name: 'High performance Storage',
              list: ` •  2x25Gbps RoCEv2 Network<br>
                      •  Thousands of RDMA QPs<br>
                      •  NVMe-oF/RoCEv2<br>
                      •  SPDK-based storage service<br>
                      •  Parallel File Storage<br>
                      •  Support Distributed Object Storage<br>
                    `
            },
            {
              name: 'HPC and AI/ML',
              list: ` •  2x25Gbps RoCEv2 Network improves the efficiency of computing<br>
                      •  HyperDirect， GPUDirect RDMA<br>
                      •  PFC and DCQCN, Go-Back-N repeat protocol<br>
                      •  Programmable Congestion Control interface(FlexCC) allows full programmability<br>
                      •  Adaptive Path and QP Trace<br>
                      •  Standard Verbs interfaces，support NCCL and openMPI<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Product Specifications',
              list: ` •  Maximum total bandwidth: 50Gbp/s<br>
                      •  Form factors: PCIe FHHL<br>
                      •  Host interface: PCIe Gen4.0 x16<br>
                      •  Network interfaces: 2x25GbE SFP28<br>
                      •  TDP: 75W<br>

                    `
            },
            {
              name: 'RDMA over Converged Ethernet(RoCE)',
              list: ` •  RoCEv2 networking<br>
                      •  HyperDirect and GPUDirect RDMA<br>
                      •  Programmable Congestion Control interface<br>
                      •  PFC and DCQCN<br>
                      •  Go-Back-N Repeat protocol<br>
                      •  QP Trace<br>
                      •  Adaptive Path<br>
                      •  NCCL and OpenMPI<br>
                    `
            },
            {
              name: 'Stateless offloads',
              list: ` •  TSO, checksum offload<br>
                      •  VLAN tag insertion/stripping<br>
                      •  Receive Side Scaling (RSS)<br>
                      •  FDR, Receive flow steering<br>
                    `
            },
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  Accelaration with SR-IOV <br>
                      •  up to 1K virtual functions <br>
                      •  8 physical funcations <br>
                      •  Support for VXLAN, GRE tunneling offload <br>
                    `
            },
            {
              name: 'Flow control',
              list: ` •  End-to-end QoS and congestion control<br>
                      •  Flow-based statistics<br>
                    `
            },
            {
              name: 'Storage Accelerations',
              list: ` •  RDMA Storage networking<br>
                      •  NVMe-oF offloads for target machine<br>
                    `
            },
            {
              name: 'Manageability and Remote Boot',
              list: ` •  SMBus/I2C, NC-SI, UART<br>
                      •  PXE booting and UEFI<br>
                      •  FRU, CPLD<br>
                      •  Firmware upgrade and Management Tools<br>
                    `
            },
          ]
        }
      },
      dataJsonF50: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance DPU — metaFusion-50',
          desc: 'Product Introduction',
          desc_details: 'The rise of the computing power economy underscores the value of computing power. To overcome bottlenecks to chip processes in the post-Moore’s Law era, unleashing computing power through high performance networks is emerging. Yunsilicon unveiled metaFusion-50 with self-owned intellectual property. This high performance DPU is applied in the next-generation cloud computing infrastructure and offers high performance RDMA network capabilities. Equipped with Yunsilicon’s original HyperDirect technology, it contributes to faster calculations of GPU and AI chips. Furthermore, Yunsilicon’s open programmable congestion control algorithm platform can assist users in designing and applying appropriate congestion control algorithms according to the service type, thereby improving the end-to-end reliability of networks.',
          cImg: require('../assets/20240205/metaFusion-50.png'),
          cText: 'metaFusion-50'
        },
        content: {
          htitle: 'Product Highlights',
          hmsg: 'The high performance DPU metaFusion-50 provides rich virtualization capabilities, high performance open networks, and flexible storage solutions for cloud computing infrastructure. It also leverages Yunsilicon’s innovative HyperDirect technology and the programmable congestion control algorithm platform to deliver high performance network capabilities.',
          item: [
            {
              name: 'Rich virtualization capabilities',
              list: ` •	 Supports large-scale SR-IOV PFs and VFs <br>
                      •	 Supports VirtIO-net offload and acceleration <br>
                      •  Supports RDMA virtualization <br>
                      •  Supports hot plug of PF devices and vDPA live migration <br>
                    `
            },
            {
              name: 'High performance RoCE v2 networks',
              list: ` •  Supports 2x25G RoCE v2 networks for more efficient computing in many fields such as HPC and AI/ML<br>
                      •  Supports high performance RoCE v2 Overlay, meeting the requirements of VPC RoCE v2 networks<br>
                      •  Large-scale RDMA QP and rich flow control capabilities<br>
                    `
            },
            {
              name: 'Offload and acceleration for cloud computing infrastructure',
              list: ` •  Supports SR-IOV and emulated devices (such as VirtIO-net, VirtIO-blk, and RDMA)<br>
                      •  Achieves high performance network forwarding based on programmable pipelines<br>
                      •  Supports the flow table Cache Pin feature and flexible offload policies<br>
                      •  Implements hardware-assisted HQoS to ensure the quality of infrastructure networks<br>
                      •  Offers flexible storage solutions based on SPDKs<br>
                      •	 Supports diverse O&M approaches, such as INT, mirror, and telemetry<br>
                    `
            },
            {
              name: 'HyperDirect technology and programmable congestion control',
              list: ` •  Implements GPUDirect RDMA based on the HyperDirect technology<br>
                      •  Provides an open programmable congestion control algorithm platform so that users can develop their algorithms tailored to their specific service needs<br>
                      •  Supports diverse congestion control factors (such as DCQCN alpha/timeout and Flow CNP) and flow control by different granularities (for example, rate-based or window-based flow control)<br>
                    `
            },
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaFusion系列.png'),
          item: [
            {
              name: 'High-Performance Bare Metal Servers',
              list: ` •  VirtIO-net network devices live migratin<br>
                      •  VirtIO-blk based cloud storage  live migration<br>
                      •  remote cloud storage booting<br>
                    `
            },
            {
              name: 'Elastic Compute Servers',
              list: ` •  vDPA live migrating<br>
                      •  QoS capability that supports virtual machine granularity<br>
                    `
            },
            {
              name: 'Large-scale Elastic Container Cloud',
              list: ` •  Support for large-scale VF devices<br>
                      •  Flow table Cache Pin that supports long connections<br>
                    `
            },
            {
              name: 'High-performance computing',
              list: ` •  Support for RoCEv2 Underlay and Overlay<br>
                      •  GPUDirect RDMA<br>
                      •  Programmable Congestion Control Algorithm Platform<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Adapter hardware',
              list: ` •  Size：FHHL double-wide<br>
                      •  Host interface：PCIe Gen4.0 x16<br>
                      •  network interface：2x25GbE SFP28<br>
                      •  management interface：RJ45<br>
                      •  BMC interface：RJ45<br>
                      •  TDP：150W<br>
                    `
            },
            {
              name: 'CPU',
              list: ` •  Intel® Xeon® D-1736 @ 2.3GHz 8 Cores, 16 threads<br>
                    `
            },
            {
              name: 'RAM',
              list: ` •  CPU：32G，DDR4 x2（dual-channel）<br>
                      •  FPGA：up to16G,DDR4 x4（quad-channel）<br>
                    `
            },
            {
              name: 'RoCEv2',
              list: ` •  HyperDirect and GPUDirect RDMA<br>
                      •  programmable congestion control<br>
                      •  RoCEv2 Underlay and Overlay<br>
                    `
            },
            
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  SR-IOV <br>
                      •  VirtIO-net and VirtIO-blk offload and acceleration  <br>
                      •  bare Metal VirtIO deviceslive migrating <br>
                      •  vDPA devices live migrating <br>
                    `
            },
            {
              name: 'Networking',
              list: ` •  DPDK-OVS data plane offload<br>
                      •  Flow table Cahce Pin and offload strategy<br>
                      •  HQoS<br>
                      •  hardware Meter<br>
                      •  Security Group<br>
                      •  LAG<br>
                    `
            },
            {
              name: 'Storage',
              list: ` •  SPDK storage applications<br>
                      •  Cloud Storage mounting and bare metal cloud device booting<br>
                      •  RDMA based high performance storage<br>
                    `
            },
            {
              name: 'Management',
              list: ` •  Onboard BMC and OOB management interface <br>
                      •  NCSI、UART and USB interface<br>
                    `
            },
          ]
        }
      },
      dataJsonF200: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance DPU — metaFusion-200',
          desc: 'Product Introduction',
          desc_details: 'The rise of the computing power economy underscores the value of computing power. To overcome bottlenecks to chip processes in the post-Moore’s Law era, unleashing computing power through high performance networks is emerging. Yunsilicon unveiled metaFusion-200 with self-owned intellectual property. This high performance DPU is applied in the next-generation cloud computing infrastructure and offers high performance RDMA network capabilities. Equipped with Yunsilicon’s original HyperDirect technology, it contributes to faster calculations of GPU and AI chips. Furthermore, Yunsilicon’s open programmable congestion control algorithm platform can assist users in designing and applying appropriate congestion control algorithms according to the service type, thereby improving the end-to-end reliability of networks.',
          cImg: require('../assets/20240205/metaFusion-200.png'),
          cText: 'metaFusion-200'
        },
        content: {
          htitle: 'Product Highlights',
          hmsg: ' The high performance DPU metaFusion-200 provides rich virtualization capabilities, high performance open networks, and flexible storage solutions for cloud computing infrastructure. It also leverages Yunsilicon’s innovative HyperDirect technology and the programmable congestion control algorithm platform to deliver high performance network capabilities.',
          item: [
            {
              name: 'Rich virtualization capabilities',
              list: ` •	 Supports large-scale SR-IOV PFs and VFs <br>
                      •	 Supports VirtIO-net offload and acceleration <br>
                      •  Supports RDMA virtualization <br>
                      •  Supports hot plug of PF devices and vDPA live migration <br>
                    `
            },
            {
              name: 'High performance RoCE v2 networks',
              list: ` •  Supports 2x100G RoCE v2 networks for more efficient computing in many fields such as HPC and AI/ML<br>
                      •  Supports high performance RoCE v2 Overlay, meeting the requirements of VPC RoCE v2 networks<br>
                      •  Large-scale RDMA QP and rich flow control capabilities<br>
                    `
            },
            {
              name: 'Offload and acceleration for cloud computing infrastructure',
              list: ` •  Supports SR-IOV and emulated devices (such as VirtIO-net, VirtIO-blk, and RDMA)<br>
                      •  Achieves high performance network forwarding based on programmable pipelines<br>
                      •  Supports the flow table Cache Pin feature and flexible offload policies<br>
                      •  Implements hardware-assisted HQoS to ensure the quality of infrastructure networks<br>
                      •  Offers flexible storage solutions based on SPDKs<br>
                      •	 Supports diverse O&M approaches, such as INT, mirror, and telemetry<br>
                    `
            },
            {
              name: 'HyperDirect technology and programmable congestion control',
              list: ` •  Implements GPUDirect RDMA based on the HyperDirect technology<br>
                      •  Provides an open programmable congestion control algorithm platform so that users can develop their algorithms tailored to their specific service needs<br>
                      •  Supports diverse congestion control factors (such as DCQCN alpha/timeout and Flow CNP) and flow control by different granularities (for example, rate-based or window-based flow control)<br>
                    `
            },
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaFusion系列.png'),
          item: [
            {
              name: 'High-Performance Bare Metal Servers',
              list: ` •  VirtIO-net network devices live migratin<br>
                      •  VirtIO-blk based cloud storage  live migration<br>
                      •  remote cloud storage booting<br>
                    `
            },
            {
              name: 'Elastic Compute Servers',
              list: ` •  vDPA live migrating<br>
                      •  QoS capability that supports virtual machine granularity<br>
                    `
            },
            {
              name: 'Large-scale Elastic Container Cloud',
              list: ` •  Support for large-scale VF devices<br>
                      •  Flow table Cache Pin that supports long connections<br>
                    `
            },
            {
              name: 'High-performance computing',
              list: ` •  Support for RoCEv2 Underlay and Overlay<br>
                      •  GPUDirect RDMA<br>
                      •  Programmable Congestion Control Algorithm Platform<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Adapter hardware',
              list: ` •  Size：FHHL double-wide<br>
                      •  Host interface：PCIe Gen4.0 x16<br>
                      •  network interface：2x100GbE SFP28<br>
                      •  management interface：RJ45<br>
                      •  BMC interface：RJ45<br>
                      •  TDP：150W<br>
                    `
            },
            {
              name: 'CPU',
              list: ` •  Intel® Xeon® D-1736 @ 2.3GHz 8 Cores, 16 threads<br>
                    `
            },
            {
              name: 'RAM',
              list: ` •  CPU：32G，DDR4 x2（dual-channel）<br>
                      •  FPGA：up to16G,DDR4 x4（quad-channel）<br>
                    `
            },
            {
              name: 'RoCEv2',
              list: ` •  HyperDirect and GPUDirect RDMA<br>
                      •  programmable congestion control<br>
                      •  RoCEv2 Underlay and Overlay<br>
                    `
            },
            
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  SR-IOV <br>
                      •  VirtIO-net and VirtIO-blk offload and acceleration  <br>
                      •  bare Metal VirtIO deviceslive migrating <br>
                      •  vDPA devices live migrating <br>
                    `
            },
            {
              name: 'Networking',
              list: ` •  DPDK-OVS data plane offload<br>
                      •  Flow table Cahce Pin and offload strategy<br>
                      •  HQoS<br>
                      •  hardware Meter<br>
                      •  Security Group<br>
                      •  LAG<br>
                    `
            },
            {
              name: 'Storage',
              list: ` •  SPDK storage applications<br>
                      •  Cloud Storage mounting and bare metal cloud device booting<br>
                      •  RDMA based high performance storage<br>
                    `
            },
            {
              name: 'Management',
              list: ` •  Onboard BMC and OOB management interface <br>
                      •  NCSI、UART and USB interface<br>
                    `
            },
          ]
        }
      },
      dataJsonMetaScale: {
        banner: {
          bgImg: require('../assets/20240205/banner.png'),
          title: 'High Performance SmartNICs — metaScale',
          desc: 'Introduction',
          desc_details: 'metaScale is a SmartNIC product designed for modern data centers, cloud environments, and high-performance networks and storage in AI computing centers. It offers excellent network forwarding performance, mature platform compatibility, a rich software ecosystem, and flexible solution capabilities.',
          cImg: require('../assets/20240205/metaScale.png'),
          cText: 'metaScale'
        },
        content: {
          htitle: 'Highlight',
          hmsg: '',
          item: [
            {
              name: 'RoCEv2 High-performance Network',
              list: `RoCEv2 With line-speed throughput, a general Verbs programming interface, and compatibility with NCCL and OpenMPI collective communication libraries, as well as the SPDK high-performance storage software ecosystem.`
            },
            {
              name: 'Programmable Congestion Control Algorithm (FlexCC)',
              list: `Congestion control algorithms and retransmission capabilities play a crucial role in RDMA networks. metaScale offers the widely used DCQCN flow control algorithm, along with programmable congestion control functionality called FlexCC. Users can customize congestion control algorithms based on input factors such as events and RTT, achieving end-to-end congestion control through rate adjustments or window tuning.`
            },
            {
              name: 'HyperDirect Technology',
              list: `HyperDirect technology is a self-developed GPUDirect RDMA feature by Yunsilicon. With HyperDirect technology, GPU memory addresses can be registered to the metaScale intelligent network card, allowing the metaScale intelligent network card to read from and write data to the GPU's memory directly.`
            },
            {
              name: 'vSwitch Offload Acceleration Capability',
              list: ` vSwitch offload acceleration capability is a hardware acceleration unloading feature provided by metaScale specifically for vSwitch. It leverages the DPDK RTE_FLOW interface to offload vSwitch flow tables. With this capability, hardware can directly handle network data transmission and reception. Additionally, on the metaScale platform, it facilitates stateful security group connection tracking, statistics, packet header modification, and tunnel encapsulation and decapsulation. `
            },
            {
              name: 'Flow Table Cache-Pin Technology',
              list: `The Flow Table Cache-Pin technology is designed for sessions that are delay-sensitive or require frequent table lookups, such as RoCEv2 Overlay, elephant flows, and long connections. It involves pushing the corresponding table entries to the on-chip cache of the metaScale SmartNIC based on policies. This optimization enhances the overall forwarding efficiency of the metaScale SmartNIC.`
            },
          ]
        },
        content2: {
          htitle: 'Product Solutions',
          img1: require('../assets/20240205/metaFusion系列.png'),
          item: [
            {
              name: 'High-Performance Bare Metal Servers',
              list: ` •  VirtIO-net network devices live migratin<br>
                      •  VirtIO-blk based cloud storage  live migration<br>
                      •  remote cloud storage booting<br>
                    `
            },
            {
              name: 'Elastic Compute Servers',
              list: ` •  vDPA live migrating<br>
                      •  QoS capability that supports virtual machine granularity<br>
                    `
            },
            {
              name: 'Large-scale Elastic Container Cloud',
              list: ` •  Support for large-scale VF devices<br>
                      •  Flow table Cache Pin that supports long connections<br>
                    `
            },
            {
              name: 'High-performance computing',
              list: ` •  Support for RoCEv2 Underlay and Overlay<br>
                      •  GPUDirect RDMA<br>
                      •  Programmable Congestion Control Algorithm Platform<br>
                    `
            },
          ]
        },
        content3: {
          htitle: 'Product Features',
          item: [
            {
              name: 'Adapter hardware',
              list: ` •  Size：FHHL double-wide<br>
                      •  Host interface：PCIe Gen4.0 x16<br>
                      •  network interface：2x100GbE SFP28<br>
                      •  management interface：RJ45<br>
                      •  BMC interface：RJ45<br>
                      •  TDP：150W<br>
                    `
            },
            {
              name: 'CPU',
              list: ` •  Intel® Xeon® D-1736 @ 2.3GHz 8 Cores, 16 threads<br>
                    `
            },
            {
              name: 'RAM',
              list: ` •  CPU：32G，DDR4 x2（dual-channel）<br>
                      •  FPGA：up to16G,DDR4 x4（quad-channel）<br>
                    `
            },
            {
              name: 'RoCEv2',
              list: ` •  HyperDirect and GPUDirect RDMA<br>
                      •  programmable congestion control<br>
                      •  RoCEv2 Underlay and Overlay<br>
                    `
            },
            
          ],
          item2: [
            {
              name: 'Virtualization',
              list: ` •  SR-IOV <br>
                      •  VirtIO-net and VirtIO-blk offload and acceleration  <br>
                      •  bare Metal VirtIO deviceslive migrating <br>
                      •  vDPA devices live migrating <br>
                    `
            },
            {
              name: 'Networking',
              list: ` •  DPDK-OVS data plane offload<br>
                      •  Flow table Cahce Pin and offload strategy<br>
                      •  HQoS<br>
                      •  hardware Meter<br>
                      •  Security Group<br>
                      •  LAG<br>
                    `
            },
            {
              name: 'Storage',
              list: ` •  SPDK storage applications<br>
                      •  Cloud Storage mounting and bare metal cloud device booting<br>
                      •  RDMA based high performance storage<br>
                    `
            },
            {
              name: 'Management',
              list: ` •  Onboard BMC and OOB management interface <br>
                      •  NCSI、UART and USB interface<br>
                    `
            },
          ]
        }
      }
    }
  },
  computed: {
  },
  created() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {  
      this.pcOrmb = false
    }else{  
      this.pcOrmb = true
    } 
  },
  mounted() {
    if (this.$route.query.id == 5) {
      this.dataJson = this.dataJsonF200
    } else if (this.$route.query.id == 4) {
      this.dataJson = this.dataJsonF50
    } else if (this.$route.query.id == 3) {
      this.dataJson = this.dataJsonC200
    } else if (this.$route.query.id == 2) {
      this.dataJson = this.dataJsonC100
    } else if (this.$route.query.id == 1) {
      this.dataJson = this.dataJsonC50
    } else if (this.$route.query.id == 8) {
      this.dataJson = this.dataJsonMetaScale
    } else {
      this.dataJson = this.dataJsonC100
    }
    
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.blockThree{
  background-color: #fff;
  .content{
    width: 63vw;
    margin: auto;
    padding: 4vw 0;
    .title{
      font-size: 1.8vw;
      font-weight: bold;
      color: #0B71B7;
    }
    .itemBoxAll{
      display: flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2vw;
      .itemBox{
        flex: 0 0 calc(50% - 5vw);
        .item{
          color: #333;
          margin-bottom: 2vw;
          .name{
            font-size: 1vw;
            font-weight: 700;
          }
          .li{
            font-size: .8vw;
            line-height: 1.6vw;
            margin-top: 1vw;
          }
        }
      }
    }
  }
}
.blockTwo{
  background-color: #F2F4F7;
  .content{
    width: 63vw;
    margin: auto;
    padding: 4vw 0;
    .title{
      font-size: 1.8vw;
      font-weight: bold;
      color: #0B71B7;
    }
    .imgBox{
      display: flex;
      justify-content: space-between;
      padding: 3vw 0;
      .img{
        width: calc(50% - 3vw);
        margin: 0 auto;
      }
    }
    .itemBox{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item{
        color: #333;
        padding: 2vw;
        flex: 0 0 calc(50% - 5vw);
        margin-bottom: 2vw;
        .name{
          font-size: 1vw;
          font-weight: 700;
        }
        .li{
          font-size: .8vw;
          line-height: 1.6vw;
          margin-top: 1vw;
        }
      }
    }
  }
}
.blockOne{
  background-color: #fff;
  .content{
    width: 63vw;
    margin: auto;
    .title{
      font-size: 1.8vw;
      font-weight: bold;
      color: #0B71B7;
      margin-top: 4vw;
    }
    .msg{
      font-size: 0.85vw;
      line-height: 1.5vw;
      margin-top: 1vw;
      color: #666666;
    }
    .itemBox{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 1.2vw;
      margin-top: 1.2vw;
      padding-bottom: 3vw;
      border-top: solid 1px #EEEEEE;
      .item{
        background-color: #0B71B7;
        color: #fff;
        padding: 2vw;
        flex: 0 0 calc(50% - 5vw);
        margin-bottom: 2vw;
        .name{
          font-size: 1vw;
          font-weight: 700;
        }
        .li{
          font-size: .8vw;
          line-height: 1.6vw;
          margin-top: 1vw;
        }
      }
    }
  }
}
.dynamic{
  background: #F6F7F9;
}
.banner{
  height: 600px;
  display: flex;
  align-items: center;
  background-size:  cover !important;
  background-position: 50% 50% !important;
  .content{
    width: 63vw;
    margin: auto;
    display: flex;
    align-items: center;
    .cBox{
      margin-left: auto;
      .img{
        width: 19vw;
        margin: auto;
      }
      .text{
        font-size: 1.2vw;
        font-weight: bold;
        color: #000;
        margin-top: 1vw;
        text-align: center;
      }
    }
    .box{
      width:30vw;
      .title{
        font-size: 1.8vw;
        font-weight: bold;
        color: #0B71B7;
      }
      .desc{
        font-size: 1.4vw;
        font-weight: bold;
        color: #0B71B7;
        margin-top: 2vw;
      }
      .desc_details{
        font-size: 0.85vw;
        line-height: 1.5vw;
        margin-top: .5vw;
        color: #666666;
      }
    }
    
  }
}
@media screen and (max-width:750px){
  .blockThree{
    background-color: #fff;
    .content{
      width: 80vw;
      margin: auto;
      padding: 8vw 0;
      .title{
        font-size: 4vw;
        font-weight: bold;
        color: #0B71B7;
      }
      .itemBoxAll{
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4vw;
        .itemBox{
          flex: 0 0 calc(50% - 5vw);
          .item{
            color: #333;
            margin-bottom: 4vw;
            .name{
              font-size: 3vw;
              font-weight: 700;
            }
            .li{
              font-size: 2.5vw;
              line-height: 1.6;
              margin-top: 3vw;
            }
          }
        }
      }
    }
  }
  .blockTwo{
    background-color: #F2F4F7;
    .content{
      width: 80vw;
      margin: auto;
      padding: 4vw 0;
      .title{
        font-size: 4vw;
        font-weight: bold;
        color: #0B71B7;
      }
      .imgBox{
        display: flex;
        justify-content: space-between;
        padding: 6vw 0;
        .img{
          width: calc(50% - 3vw);
        }
      }
      .itemBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
          color: #333;
          padding: 4vw;
          flex: 0 0 calc(50% - 10vw);
          margin-bottom: 4vw;
          .name{
            font-size: 3vw;
            font-weight: 700;
          }
          .li{
            font-size: 2.5vw;
            line-height: 1.6;
            margin-top: 3vw;
          }
        }
      }
    }
  }
  .blockOne{
    background-color: #fff;
    .content{
      width: 80vw;
      margin: auto;
      .title{
        font-size: 4vw;
        font-weight: bold;
        color: #0B71B7;
        margin-top: 6vw;
      }
      .msg{
        font-size: 3vw;
        line-height: 1.6;
        margin-top: 3vw;
        color: #666666;
        border-bottom: solid 1px #EEEEEE;
        padding-bottom: 3vw;
      }
      .itemBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 3vw;
        padding-bottom: 6vw;
        
        .item{
          background-color: #0B71B7;
          color: #fff;
          padding: 4vw;
          flex: 0 0 calc(50% - 10vw);
          margin-bottom: 4vw;
          .name{
            font-size: 3vw;
            font-weight: 700;
          }
          .li{
            font-size: 2.5vw;
            line-height: 1.6;
            margin-top: 3vw;
          }
        }
      }
    }
  }
  .banner{
    height: auto;
  }
  .banner{
    .content{
      flex-direction: column-reverse;
      width: auto;
      justify-content: center;
      padding: 10vw 0;
      .cBox{
        margin: auto;
        .img{
          width: 80vw;
          margin: auto;
        }
        .text{
          font-size: 4vw;
          font-weight: bold;
          color: #000;
          margin-top: 2vw;
          text-align: center;
        }
      }
      .box{
        width: 80%;
        margin-top: 3vw;
        .title{
          font-size: 6vw;
          font-weight: bold;
          color: #0B71B7;
        }
        .desc{
          font-size: 4vw;
          font-weight: bold;
          color: #0B71B7;
          margin-top: 6vw;
        }
        .desc_details{
          font-size: 3vw;
          line-height: 1.6;
          margin-top: 2vw;
          color: #666666;
        }
      }
    }
  }
  
}
</style>